import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

export default {
  name: "blackList",
  mixins: [mixins],
  data() {
    return {
      showImpDialog: false,
      inputFile: '',
      columns: [{
        label: '停车场名称',
        prop: 'parkingName'
      }, {
        label: '停车编码',
        prop: 'parkingCode'
      }, {
        label: '车牌号码',
        prop: 'plateNo',
        customRender: props => {
          if (!props.row.plateNo) {
            return '未知';
          } else {
            return `${props.row.plateNo}(${props.row.plateColor})`;
          }
        }
      }, {
        label: '开始时间',
        prop: 'startTime'
      }, {
        label: '结束时间',
        prop: 'endTime'
      }],
      topButtons: [{
        name: '新增',
        code: 'bikeBlackList_add',
        type: 'primary',
        click: this.Add,
        permissions: ['bikeBlackList_add']
      }
      /*{
        name: '导入',
        code: 'bikeBlackList_import',
        type: 'primary',
        click: this.importInfo,
        permissions: ['bikeBlackList_import']
      }*/],
      linkButtons: [{
        name: '删除',
        code: 'bikeBlackList_del',
        type: 'danger',
        click: this.Del,
        permissions: ['bikeBlackList_del']
      }, {
        name: '编辑',
        code: 'bikeBlackList_edit',
        click: this.Edit,
        permissions: ['bikeBlackList_edit']
      }],
      // 搜索字段
      searchItems: [{
        prop: 'state',
        label: '状态',
        type: 'select',
        items: [{
          label: '有效',
          value: 0
        }, {
          label: '失效',
          value: 1
        }],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }]
    };
  },
  methods: {
    Add() {
      this.$router.push('/bikeBlackListAdd');
    },
    downTemplate() {
      const loading = ElLoading.service();
      this.$api.business.blackList.exportTemplate().then(data => {
        this.blobFile(data, '黑名单导入模板文件.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {
          this.showImpDialog = false;
        });
      }).finally(() => {
        loading.close();
      });
    },
    fileChange(ev) {
      let selectFile = ev.target.files[0];
      if (selectFile) {
        let name = selectFile.name;
        let type = name.substring(name.lastIndexOf('.') + 1);
        if (type !== 'xls' && type !== 'xlsx') {
          this.$message.error('只能上传excel文件');
          this.inputFile = '';
        } else {
          this.inputFile = selectFile;
        }
      }
    },
    importInfo() {
      this.showImpDialog = true;
    },
    doImp() {
      if (!this.inputFile) {
        ElMessage.error('请选择文件');
        return;
      }
      const loading = ElLoading.service();
      // 构造一个 FormData，把后台需要发送的参数添加到FormData里面，这样就可以直接把formData作为参数传递了
      let formData = new FormData();
      formData.append('file', this.inputFile); //接口需要传递的参数
      this.$api.basic.dataImportInfo.importBlackFile(formData).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.inputFile = '';
          this.showImpDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    Edit(row) {
      this.$router.push({
        path: '/bikeBlackListAdd',
        query: {
          id: row.id
        }
      });
    },
    Del(row) {
      ElMessageBox.confirm('确认要删除该黑名单吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.blackList.del({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};